@import 'src/styles/variables/colors.scss';

.a-star-icon {
  &.-compact {
    padding: 0;
  }
  &.-muted {
    .a-star-icon__gradient,
    .a-star-icon__path {
      stop-color: $slateGray;
      stroke: $slateGray;
    }
  }
}
