@import 'src/styles/variables/variables';

.ResponsiveContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  // this keeps the video from stretching vertically beyond the viewport in really wide aspect ratios
  // e.g. phones in landscape view.
  @media (min-aspect-ratio: 2 / 1) {
    padding-bottom: 33%;
  }

  .Video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}