@import 'src/styles/variables/variables';
@include useTheme();

.Root {
  margin: 26px auto 0;
  background-color: $tint1;
  max-width: 270px;
  padding: 19px;
  font-size: 16px;
  line-height: 15px;

  b {
    font-weight: 500;
  }

  button {
    color: $core8;
    font-weight: 500;
  }
}