.comparisonSliderItem,
.comparisonSliderItemTarget {
  img {
    width: 100%;
    height: auto;
  }
}

.comparisonSliderItemTarget {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  //clip-path: polygon(
  //    calc(var(--exposure) + 1px) 0,
  //    100% 0,
  //    100% 100%,
  //    calc(var(--exposure) + 1px) 100%);
  clip-path: polygon(
      calc(var(--exposure) + 0px) 0,
      100% 0,
      100% 100%,
      calc(var(--exposure) + 0px) 100%);
}
