@import 'src/styles/variables/variables';

.MobileAppConversionBanner {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: row;
  height: 76px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999999999999;
}

.DismissButton {
  background-color: $white;
  border: 0;
  margin: 0 10px;
  padding: 0;

  span {
    height: 24px;
    width: 24px;
    color: $mutedGray;
  }
}

.IosAppIcon {
  border-radius: 10px;
  width: 63px;
  height: 63px;
  margin: 0 10px 0 0;
}

.DetailsContainer {
  margin-right: 10px;
  flex: 1;

  p {
    margin: 0;
    padding: 0;
  }

  .Title {
    min-height: 2px;
    font-weight: bold;
    color: $black;
    padding-top: 5px;
    padding-bottom: 1px;
    font-size: 12px;
    line-height: 1;
  }

  .Description {
    min-height: 11px;
    overflow: hidden;
    color: $black;
    font-size: 11px;
    line-height: 1;
  }

  .Reviews {
    display: flex;
    align-items: center;

    .ReviewCount {
      margin-left: 10px;
      position: relative;
      top: 1px;
    }
  }
}

.OpenButton {
  font-size: 12px;
  min-width: auto;
  height: auto;
  padding: 10px 10px;
  margin-right: 10px;
}