@import 'src/styles/variables/colors';

.a-button-svg-icon {
  &:focus {
    box-shadow: none;
  }

  :global {
    .stroke {
      stroke: black;
      stroke-width: 3px;
    }
  }

  &.-active {
    :global {
      .stroke {
        stroke-width: 8px;
      }
    }
  }

  &.-disabled {
    :global {
      .stroke {
        stroke: $gray;
        stroke-width: 3px;
      }
    }
  }
}
