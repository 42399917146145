@import '../../main.module.scss';
@include useTheme();

.Container {
    display: block;
    background: #CBDFE9;
    padding: 58px 0 84px 0;

  @include min-width(small-tablet) {
    padding: 57px 0 63px 0;
  }

  @include min-width(desktop) {
    padding: 87px 0 127px 0;
  }

  .HeaderContainer {
    display: flex;
    justify-content: center;

    .Title {
      display: block;
      text-align: center;
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;

      @include min-width(large-tablet) {
        font-weight: 400;
        font-size: 48px;
        line-height: 58px;
      }

      @include min-width(desktop) {
        margin: 0 0 48px 0;
      }
    }
  }

  .FeedContainer {
    display: grid;
    gap: 10px;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
    max-width: 322px;

    @include min-width(tablet) {
      max-width: 769px;
      grid-template-columns: repeat(4, 1fr);
      gap: 11px;
    }

    @include min-width(desktop) {
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }

    @include min-width(large-desktop) {
      max-width: 1290px;
    }

    a {
      margin: 0 auto;
    }

    img {
      object-fit: cover;
      width: 156px;
      height: 156px;
      display: block;

      @include min-width(small-tablet) {
        width: 184px;
        height: 184px;
      }

      @include min-width(large-desktop) {
        width: 311px;
        height: 311px;
      }
    }

    img:hover {
      opacity: 0.4;
    }
  }
}
