@import 'src/styles/variables/variables';

.a-input-validation-feedback {
  color: $black;
  font-size: $font-size-xs;
  visibility: hidden;

  &.-is-invalid {
    color: $red;
    visibility: visible;
  }

  &.-is-valid {
    color: $black;
    visibility: visible;
  }
}