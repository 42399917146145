@import 'src/styles/variables/typography';
@import 'src/styles/variables/variables.scss';

.a-input-label {
  font-size: $font-size-sm;

  &.-md {
    font-size: $font-size-md;
  }

  &.-lg {
    font-size: $font-size-lg;
  }

  &__required-asterisk {
    color: $red;
  }
}
