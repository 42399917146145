@import '../../../../styles/variables/variables';
@import '../../../../styles/mixins/mixins';

$hoverColor: #e36650;
$boxShadow: rgba(0,0,0,0.12);

.ButtonPrimary {
  @include font('GT Walsheim');
  align-items: center;
  background-color: $coral;
  border: none;
  border-radius: 22px;
  box-shadow: 0 18px 24px $boxShadow;
  color: $white;
  display: inline-flex;
  font-size: 14px;
  height: 42px;
  justify-content: center;
  line-height: 1;
  min-width: 130px;
  outline: none !important;
  padding: 0 30px;
  text-decoration: none !important;

  &.active {
    opacity: 0.8;
  }

  &.isCompact {
    min-width: auto;
  }

  &:disabled,
  &.isDisabled {
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background-color: $hoverColor;
    color: $white;
  }
}
