@import 'src/styles/variables/variables';

$ShareBorderColor: rgba(247,122,100,0.59);
$backgroundColor: rgba($black, .05);

.Modal {
  z-index: 2100; // even more higher than the product modal
}

.Root {
  height: 100%;
  width: 100%;
  min-height: 310px;

  @include min-width(large-tablet) {
    display: flex;
    height: auto;
    width: 560px;
  }
}

.ModalDialog {
  @include min-width(large-tablet) {
    width: 100%;
    margin: 0;
    max-width: none;

    :global {
      .modal-content {
        width: 100%;
      }
    }
  }

  @include min-width(large-tablet) {
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(28, 34, 51, 0.7);
    margin: 0 auto;
    max-width: 560px;
    overflow: hidden;
    position: relative;
    top: 36%;
    transform: translateY(-50%) !important;
  }
}

.CloseButton {
  padding-right: 16px;
  padding-top: 14px;
  position: absolute;
  right: 0;
  z-index: 3;
}

.Content {
  padding: 4px;

  @include min-width(small-tablet) {
    margin: 0 40px;
  }

  @include min-width(desktop) {
    margin: 0 20px;
    width: 520px;
  }
}

.Header {
  border-bottom: 1px solid $gallery;
  padding: 20px 0;
  size: 20px;
  text-align: center;
}

.HeaderText {
  font-family: Sailec, sans-serif;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.2px;
  line-height: 19px;
  margin: 10px 0;
}

.ShareButtons {
  display: flex;
  justify-content: center;
  padding: 40px 0;

  button {
    @include max-width(large-phone) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.ShareButton.ShareButton {
  @include font('GT Walsheim', 400);
  align-items: center;
  border: 1px solid $ShareBorderColor;
  box-shadow: none;
  color: $charcoal;
  display: flex;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;

  &:first-child {
    margin-right: auto;
  }

  &:last-child {
    margin-left: auto;
  }

  &:hover {
    background-color: $backgroundColor !important;
  }
}

.Icon {
  margin-right: 6px;
  position: relative;
  top: 1px;
}

.Copy {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.Url {
  color: $graceCharcoal;
  font-family: Sailec-Medium, sans-serif;
  font-size: 13px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
}

.UrlAnchor {
  text-decoration: none !important;
  &:hover {
    color: rgba($black, .5);
  }
}

.UrlWrap {
  align-items: center;
  border: 1px solid $ShareBorderColor;
  border-radius: 35px;
  display: flex;
  padding-left: 30px;
  width: 100%;
}

.CopyButton.CopyButton {
  @include font('GT Walsheim', 400);
  background-color: $coral !important;
  border: 6px solid $coral !important;
  box-shadow: none;
  color: $white;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: auto;
  padding: 0 30px;
  white-space: nowrap;
}
