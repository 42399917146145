@import 'src/styles/variables/variables';

.a-input-text {
    border: 1px solid $colorInputBorder;
    border-radius: 4px;
    color: $black;
    display: block;
    font-weight: $light;
    font-family: $sans-serif;
    font-size: $font-size-sm;
    letter-spacing: $input-letter-spacing;
    height: 38px;
    padding: 12px 19px;
    width: 100%;
    outline: none;

    &::placeholder {
      color: $mutedGray;
      font-weight: $extra-light;
    }

    &:focus {
      border: 2px solid $royalBlue;
      outline: none;
    }

    &:disabled {
      background-color: $gallery;
    }

    &.-is-valid {
      border: 2px solid $gallery
    }

    &.-is-invalid {
      border: 2px solid $red
    }
}
