@import 'src/styles/variables/variables';

@include useTheme();

@mixin comparisonSliderThumb {
  background: linear-gradient(90deg,
    transparent calc(var(--thumb-size) / 2 - 1px),
    #fff calc(var(--thumb-size) / 2 - 1px),
    #fff calc(var(--thumb-size) / 2 + 1px),
    transparent calc(var(--thumb-size) / 2 + 1px));
  color: #000;
  width: var(--thumb-size);
  height: 100%;
}

@mixin comparisonSliderThumbFocus {
  background: linear-gradient(90deg,
    transparent calc(var(--thumb-size) / 2 - 10px),
    #3d7bae calc(var(--thumb-size) / 2 - 10px),
    #3d7bae calc(var(--thumb-size) / 2 - 1px),
    #fff calc(var(--thumb-size) / 2 - 1px),
    #fff calc(var(--thumb-size) / 2 + 1px),
    #3d7bae calc(var(--thumb-size) / 2 + 1px),
    #3d7bae calc(var(--thumb-size) / 2 + 10px),
    transparent calc(var(--thumb-size) / 2 + 10px));
}

.comparisonSlideLabel {
  align-items: stretch;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
}

.comparisonSliderInput {
  margin: 0 calc(var(--thumb-size) / -2);
  width: calc(100% + var(--thumb-size));
  cursor: col-resize;
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  opacity: 0;

  &::-moz-range-track {
    height: 100%;
  }

  &::-moz-range-thumb {
    @include comparisonSliderThumb;
  }

  &:focus-visible::-moz-range-thumb {
    @include comparisonSliderThumbFocus;
  }

  &::-webkit-slider-runnable-track {
    height: 100%;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;

    @include comparisonSliderThumb;
  }

  &:focus-visible::-webkit-slider-thumb {
    @include comparisonSliderThumbFocus;
  }
}

.comparisonSliderHandle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: var(--exposure);
  width: var(--thumb-size);
  transform: translate3d(-50%, 0, 0);
  color: #fff;
  pointer-events: none;
  -webkit-touch-callout: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background: #999;
    transform: translate3d(-50%, 0, 0) scale(.9, 1);
    transform-origin: center;
    opacity: 0;
  }

  @media (min-width: 768px) {
    &::before {
      transform: translate3d(-50%, 0, 0) scale(1, 1);
      opacity: 1;
    }
  }
}

.comparisonSliderBefore,
.comparisonSliderAfter {
  width: 18px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: rgba(255, 255, 255, .8);
  border-radius: 20px 0 0 20px;
  color: #999;
  text-align: center;
  transform: translate(-100%, -50%);
  font-size: 0;
  padding: 0;

  svg {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
    width: 18px;
    height: auto;
  }

  @media (min-width: 768px) {
    height: 31px;
    width: 80px;
    font-size: 12px;
    border: 1px solid #362B24;
    border-right-color: #999;
    padding: 0 0 0 23px;
    color: #362B24;
    letter-spacing: .1px;
    justify-content: flex-start;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      left: 2px;
    }
  }
}

.comparisonSliderAfter {
  border-radius: 0 20px 20px 0;
  border-right-color: #362B24;
  border-left-color: #999;
  transform: translate(0, -50%);

  svg {
    left: auto;
    right: 0;
  }

  @media (min-width: 768px) {
    padding: 0 29px 0 0;
    justify-content: flex-end;

    svg {
      right: 7px;
    }
  }
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
