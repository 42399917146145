@import 'src/styles/variables/variables';

@include useTheme();

.Input {
  input {
    border: thin solid #BEAC9E;
  }
}

.CloseButton {
  position: absolute;
  top: 15px;
  right: 15px;
}

.ModalDialog {
  max-width: 549px; 

}

.CTAWrapper {
  width: 100%;
}

.CTA {
  display: block;
  margin: 41px auto 0;
}

.Form {
  margin-top: 22px;
  text-align: left;
}

.Content {
  padding: 45px 25px;
  @include min-width(tablet) {
   padding: 61px 70px 47px;
  }
  text-align: center;
  transition: height 1s ease-in-out;

}

.Animate {
  opacity: 0;
  @include animations(fadeUp, 0.5s);
}

.Title {
  margin-bottom: 22px;
}

.Error {
  color: $core7;
}

.Info {
  color: $tint3;
}

.Forgot {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  margin: 10px 0;
  font-size: 12px;
}

.QuestionsCopy {
  margin: 20px 0 0;
}

.HaHEmail {
  font-weight: 500;
}

.PasswordInput {
  height: 70px;
}

.PasswordError {
  margin-top: 10px;
}
