@import 'src/styles/variables/variables';

@include useTheme();

.ModalDialog {
  max-width: none;
  max-height: 70vh;

  @include min-width(desktop) {
    max-width: 70vw;
  }
}

.ModalContent {
  margin: 0 25px;
}

.ModalHeader {
  padding: 0;
  border: 0;
}

.ModalBody {
  padding: 0;
}

.CloseButton {
  position: absolute;
  top: -40px;
  right: -7px;
  background: none;
  box-shadow: none;

  &:hover {
    background: none;
  }

  span {
    color: $core2;
    font-size: 18px;
    padding: 0 !important; // close icon class conflicts with bootstrap modal style
    margin: 0 !important;
  }
}

