@import '../../../styles/variables/variables';

.m-rating-fill {
  gap: 6px;

  .large {
    gap: 8px;
  }

  .small {
    gap: 2px;
  }

  &.havenly {
    gap: 4px;
  }
}

.sr-only {
  @include screenreader-only;
}