.a-star {
  &__icon {
    box-sizing: content-box;
    width: 17px;
    height: 17px;
  }

  &.-size-sm {
    .a-star__icon {
      width: 11px;
      height: 11px;
    }
  }

  &.-size-lg {
    .a-star__icon {
      width: 28px;
      height: 28px;
    }
  }
}
