@import '../../../../../styles/variables/variables';
@import '../../../../../styles/mixins/mixins';

$mobileButtonSize: 33px;
$tabletButtonSize: 35px;
$buttonShadow: 0 8px 12px 2px rgba(0, 0, 0, 0.1);

.CloseButton {
  background: white;
  box-shadow: $buttonShadow;
  cursor: pointer;
  height: $mobileButtonSize;
  padding: 0;
  width: $mobileButtonSize;

  &:hover {
    background-color: $colorBackgroundGray;
  }

  @include min-width(medium-tablet) {
    height: $tabletButtonSize;
    width: $tabletButtonSize;
  }

  span {
    color: $blueBayoux;
    display: block;
    font-weight: 100 !important;
    font-size: 9px;
    line-height: $mobileButtonSize;
    text-align: center;

    @include min-width(medium-tablet) {
      color: $black;
      line-height: $tabletButtonSize;
    }
  }
}
