@import 'src/styles/variables/variables';

@include useTheme();

.HowHavenlyWorks {
  display: block;
  position: relative;
  overflow: hidden;

  @include min-width(desktop) {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }

  .MainContainer {
    display: flex;
    flex-flow: column;
    padding: 50px 11px 0;
    margin-bottom: 26px;

    @include min-width(small-tablet) {
      padding: 50px 31px 0;
    }

    @include min-width(desktop) {
      width: 100%;
      padding: 86px 96px 0;
    }

    .SectionTitle {
      @include primaryFont(500);
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 38px;

      @include min-width(small-tablet) {
        margin-bottom: 65px;
        line-height: 18px;
      }

      @include min-width(desktop) {
        margin-bottom: 68px;
        font-size: 18px;
      }
    }

    .Row {
      display: flex;
      flex-flow: column;
      margin-bottom: 48px;
      max-width: 100%;
      align-items: center;

      @include min-width(medium-tablet) {
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 70px;
      }

      @include min-width(tablet) {
        margin-bottom: 91px;
      }

      @include min-width(desktop) {
        justify-content: flex-start;
        margin-bottom: 76px;
      }
    }

    .Step {
      @include secondaryFont(400);
      font-size: 28px;
      font-style: normal;
      line-height: 38px;
      letter-spacing: 0.4px;
      text-align: center;
      margin-bottom: 6px;

      @include min-width(medium-tablet) {
        width: 130px;
      }

      @include min-width(desktop) {
        font-size: 34px;
        line-height: 38px;
        letter-spacing: 0.4px;
        width: auto;
        margin-right: 65px;
      }

      @include min-width(large-desktop) {
        margin-right: 141px;
      }
    }

    .Content {
      text-align: center;
      margin-bottom: 26px;

      @include min-width(medium-tablet) {
        margin-bottom: 0;
        text-align: left;
        max-width: 300px;
      }

      @include min-width(desktop) {
        min-width: 433px;
        margin-right: 138px;
      }

      .Title {
        @include secondaryFont(400);
        font-size: 28px;
        font-style: normal;
        line-height: 36px;
        max-width: 400px;
        margin: 0 auto 16px;

        @include min-width(medium-tablet) {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 25px;
          max-width: none;
        }

        @include min-width(desktop) {
          font-size: 34px;
          line-height: 38px;
          letter-spacing: 0.4px;
          margin-bottom: 28px;
        }
      }

      .Description {
        @include primaryFont(400);
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.4px;
        max-width: 360px;
        margin: 0 auto;

        @include min-width(medium-tablet) {
          max-width: none;
        }
      }
    }

    .Media {
      position: relative;

      @include min-width(medium-tablet) {
        max-width: 269px;
      }

      @include min-width(desktop) {
        max-width: inherit;
      }

      .Image {
        position: relative;
        margin: 0 auto;
        width: 343px;
        min-height: 226.399px;
        max-width: 100%;

        img {
          border-radius: 10px;
        }

        @include min-width(medium-tablet) {
          min-height: 183px;
        }

        @include min-width(desktop) {
          width: 376px;
          height: 258.303px;
        }

        @include min-width(large-desktop) {
          width: 476px;
          height: 327px;
        }
      }

      &.MediaGridTwo {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 3.96px;
        grid-row-gap: 0;
        width: 353px;

        @include min-width(medium-tablet) {
          max-width: 267px;
        }

        @include min-width(desktop) {
          width: 379px;
          max-width: inherit;
        }

        .Image01 {
          position: relative;
          width: 100%;
          min-height: 232.857px;
          grid-area: 1 / 1 / 2 / 2;

          img {
            border-radius: 10px 0 0 10px;
          }

          @include min-width(medium-tablet) {
            min-height: 183px;
          }

          @include min-width(desktop) {
            width: 186.731px;
            height: 257.942px;
          }

          @include min-width(large-desktop) {
            width: 236px;
            height: 326px;
          }
        }

        .Image02 {
          position: relative;
          width: 100%;
          min-height: 238px;
          grid-area: 1 / 2 / 2 / 3;

          img {
            border-radius: 0 10px 10px 0;
          }

          @include min-width(medium-tablet) {
            min-height: 183px;
          }

          @include min-width(desktop) {
            width: 188.313px;
            height: 257.942px;
          }

          @include min-width(large-desktop) {
            width: 235px;
            height: 326px;
          }
        }
      }

      &.MediaGridThree {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 2.89px;
        grid-row-gap: 3.19px;

        @include min-width(desktop) {
          grid-column-gap: 4px;
        }

        @include min-width(large-desktop) {
          grid-column-gap: 3.94px;
          grid-row-gap: 0;
        }

        .Image01 {
          position: relative;
          grid-area: 1 / 1 / 3 / 2;

          img {
            width: 100%;
            min-height: 238px;
            border-radius: 10px 0 0 10px;

            @include min-width(medium-tablet) {
              min-height: 184px;
            }

            @include min-width(desktop) {
              width: 190px;
              min-height: 261px;
            }

            @include min-width(large-desktop) {
              width: 235.06px;
              height: 327px;
            }
          }
        }

        .Image02 {
          position: relative;
          grid-area: 1 / 2 / 2 / 3;

          img {
            border-radius: 0 10px 0 0;
            width: 172.222px;
            height: 114.997px;

            @include min-width(medium-tablet) {
              width: 132px;
              height: 89px;
            }

            @include min-width(desktop) {
              width: 188.109px;
              height: 125.937px;
            }

            @include min-width(large-desktop) {
              width: 238px;
              height: 158px;
            }
          }
        }

        .Image03 {
          position: relative;
          grid-area: 2 / 2 / 3 / 3;

          img {
            width: 172.495px;
            height: 118px;
            border-radius: 0 0 10px 0;

            @include min-width(medium-tablet) {
              width: 133px;
              height: 90px;
            }

            @include min-width(desktop) {
              width: 188.109px;
              height: 128.517px;
            }

            @include min-width(large-desktop) {
              width: 238px;
              height: 163px;
            }
          }
        }
      }
    }
  }
}

