.Dot {
  display: inline-block;
  opacity: 0;

  &:after {
    content: '.';
  }
}

.First {
  animation: first-dot 2s infinite;
}

.Second {
  animation: second-dot 2s infinite;
}

.Third {
  animation: third-dot 2s infinite;
}

@keyframes first-dot {
  0%,
  10% {
    opacity: 0;
  }

  25%,
  100% {
    opacity: 1;
  }
}

@keyframes second-dot {
  0%,
  35% {
    opacity: 0;
  }

  50%,
  100% {
    opacity: 1;
  }
}

@keyframes third-dot {
  0%,
  60% {
    opacity: 0;
  }

  75%,
  100% {
    opacity: 1;
  }
}
