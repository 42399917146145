@import 'src/styles/variables/variables';

@include useTheme();

$large-desktop-hero: 1440px;
$x-large-desktop-hero: 1620px;

.SaleHero {
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 812px;

  @include min-width(medium-tablet) {
    max-height: 1171px;
  }

  @include min-width(small-desktop) {
    max-height: 532px;
  }

  @include min-width($large-desktop-hero) {
    max-height: 748px;
  }

  @include min-width($x-large-desktop-hero) {
    max-height: 842px;
  }
}

.HeroImage {
  width: 100%;
  height: auto;
}

.HeroContentContainer {
  @include secondaryFont();
  position: absolute;
  top: $mobileNavHeight;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: $white;
  padding: 36px 0 0 32px;

  @include min-width(medium-tablet) {
    padding: 89px 61px 0;
  }

  @include min-width(small-desktop) {
    padding: 0 30px;
    margin-top: 30px;
  }

  @include min-width(desktop) {
    top: $mainNavHeight;
  }

  @include min-width($large-desktop-hero) {
    padding: 0 86px;
    margin-top: 73px;
  }

  @include min-width($x-large-desktop-hero) {
    padding: 0 100px;
    margin-top: 99px;
  }
}

.SaleName {
  @include primaryFont();
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06em;
  margin-bottom: 22px;

  @include min-width(medium-tablet) {
    font-size: 23px;
    line-height: 29px;
    margin-bottom: 40px;
  }

  @include min-width(small-desktop) {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 18px;
  }

  @include min-width(desktop) {
    font-size: 20px;
    line-height: 20px;
  }

  @include min-width($large-desktop-hero) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  @include min-width($x-large-desktop-hero) {
    font-size: 20px;
    line-height: 26px;
  }
}

.CatchPhrase {
  @include primaryFont();
  text-transform: uppercase;
  font-size: 49.85px;
  line-height: 43.87px;
  letter-spacing: -0.05em;
  margin-bottom: 36px;
  width: 360px;
  filter: drop-shadow(5px 5px 25px #0000005f);

  strong {
    font-weight: 500;
  }

  @include min-width(small-tablet) {
    width: 480px;
  }

  @include min-width(medium-tablet) {
    font-size: 96px;
    line-height: 84px;
    width: 690px;
  }

  @include min-width(small-desktop) {
    font-size: 62px;
    line-height: 55px;
  }

  @include min-width($large-desktop-hero) {
    font-size: 87px;
    line-height: 77px;
    width: 890px;
  }

  @include min-width($x-large-desktop-hero) {
    font-size: 98px;
    line-height: 87px;
    width: 999px;
  }
}

.DealSection {
  flex: 1;
  position: relative;
}

.DealBlocks {
  display: flex;
  position: absolute;

  @include min-width(medium-tablet) {
    top: 60%;
    transform: translateY(-50%);
  }

  @include min-width(small-desktop) {
    top: 35%;
    transform: translateY(-50%);
  }

  @include min-width($large-desktop-hero) {
    top: 40%;
    transform: translateY(-50%);
  }
}

.Deal {
  filter: drop-shadow(5px 5px 25px #000000BF);
}

.Small {
  @include primaryFont();
  font-size: 8px;
  line-height: 10px;
  min-height: 10px;
  letter-spacing: 0px;
  text-transform: uppercase;

  @include min-width(medium-tablet) {
    font-size: 15px;
    line-height: 20px;
    min-height: 20px;
  }

  @include min-width(small-desktop) {
    font-size: 9px;
    line-height: 12px;
    min-height: 12px;
  }

  @include min-width($large-desktop-hero) {
    font-size: 13px;
    line-height: 17px;
    min-height: 17px;
  }

  @include min-width($x-large-desktop-hero) {
    font-size: 15px;
    line-height: 19px;
    min-height: 19px;
  }

  strong {
    font-weight: 400;
  }
}

.BigNumber {
  font-size: 58px;
  line-height: 55px;
  letter-spacing: -0.01em;

  @include min-width(medium-tablet) {
    font-size: 112px;
    line-height: 107px;
  }

  @include min-width(small-desktop) {
    font-size: 70px;
    line-height: 67px;
  }

  @include min-width(desktop) {
    font-size: 80px;
    line-height: 80px;
  }

  @include min-width($large-desktop-hero) {
    font-size: 99px;
    line-height: 94px;
  }

  @include min-width($x-large-desktop-hero) {
    font-size: 112px;
    line-height: 106px;
  }
}

.SmallOperator {
  font-size: 31px;
  line-height: 23px;
  letter-spacing: -0.01em;

  @include min-width(medium-tablet) {
    font-size: 60px;
    line-height: 45px;
  }

  @include min-width(small-desktop) {
    font-size: 37px;
    line-height: 28px;
  }

  @include min-width(desktop) {
    font-size: 40px;
    line-height: 40px;
  }

  @include min-width($large-desktop-hero) {
    font-size: 52px;
    line-height: 40px;
  }

  @include min-width($x-large-desktop-hero) {
    font-size: 59px;
    line-height: 45px;
  }
}

.Divider {
  height: auto;
  margin: 15px 30px;
  border-right: 1px solid white;

  @include min-width(medium-tablet) {
    margin: 15px 54px;
  }

  @include min-width(small-desktop) {
    margin: 15px 26px;
  }

  @include min-width($x-large-desktop-hero) {
    margin: 15px 47px;
  }

  @include min-width($x-large-desktop-hero) {
    margin: 15px 54px;
  }
}

.Button {
  @include primaryFont(600);
  font-size: 8px;
  line-height: 8px;
  background-color: $white !important;
  color: $core1;
  text-transform: uppercase;
  border-radius: 15px;
  padding: 10px 20px;
  margin-top: 20px;

  @include min-width(medium-tablet) {
    font-size: 14px;
    line-height: 15px;
    border-radius: 28px;
    padding: 18px 35px;
  }

  @include min-width(small-desktop) {
    font-size: 9px;
    line-height: 9px;
    border-radius: 18px;
    padding: 8px 15px;
  }

  @include min-width($large-desktop-hero) {
    font-size: 12px;
    line-height: 13px;
    border-radius: 24px;
    padding: 11px 22px;
  }

  @include min-width($large-desktop-hero) {
    font-size: 13px;
    line-height: 15px;
    border-radius: 28px;
    padding: 13px 28px;
  }
}
